import secureStorage from "./encrypt";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
};
console.log(API_URL,"AAPPP")
export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const MAKER_ROLE_ID = 1;
export const CHECKER_ROLE_ID = 2;
export const APPROVER_ROLE_ID = 4;
export const TL_ROLE_ID = 4;
export const FINANCE_TEAM_ROLE_ID = 5;
export const OPS_TEAM_ROLE_ID = 6;
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_MDY = "mmm dd, yyyy";
export const DATE_FORMAT_INPUT_TIME = "yyyy-MM-dd h:mm aa";
export const DATE_FORMAT_FULL = "dd mmm yyyy, HH:MM TT";
export const PH_DATE_FORMAT = "dd mmm yyyy, HH:MM";

export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const BANK_LIST = [
	{ value: "Unionbank", label: "Unionbank",account_number : '000580021397',outbound_account_number : '000580021397',is_show_outbound : true }
];
export const PAYMENT_TYPE = [
	{ value: "disbursal", label: "Disbursal" },
	{ value: "refund", label: "Refund" }
];
export const SPLIT_PAYMENT_TYPE = [
	{ value: "main_dealer", label: "Dealer Payment" },
	{ value: "split_dealer", label: "Split Payment" },
	{ value: "split_dealer2", label: "Split Payment2" },
	{ value: "agent_fee", label: "Agent Fee" },
	{ value: "referral_agent", label: "Referral Agent" },
	{ value: "agent_incentive", label: "Agent Incentive" },
	{ value: "onhold_dealer", label: "Onhold Dealer Payment" },
	{ value: "aro_fee", label: "ARO Fee" },
	{ value: "incentive_payment", label: "Dealer Incentive" }

];
export const PAYMENT_ORDER = ['main_dealer', 'split_dealer', 'split_dealer2', 'referral_agent', 'agent_fee','agent_incentive'];
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;

export const REQUEST_PAYMENT_TYPE = {
	"Normal": "Payment will be made to dealer once the amount is received from the Financier",
	"Ad-Hoc": "Payment need to be made to dealer within 1 hour of request generation",
	"Advance": "Payment need to be made to dealer by today only or in the next batch of payment requests",
	"SFAP": "Payment need to be made to dealer as soon as the request gets generated",
}
export const REQUEST_PAYMENT_TYPES = [
	{ value: "normal", label: "Normal" },
	{ value: "ad_hoc", label: "Ad-Hoc" },
	{ value: "advance", label: "Advance" }
]

export const PAYMENT_REQUEST_TYPE = [
	{ value : "Normal",  label : "Normal" },
	{ value : "Ad-Hoc",  label : "Ad Hoc" },
	{ value : "Advance", label : "Advance"},
	{ value : "SFAP", label: "SFAP" }
]
export const displayAmount = (amount) => {
	let amount2display= `IDR ${amount}`;
	return amount2display;
};

export const REPORT_DATE_TYPE = [
	{ value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
	{ value: 'quarterly', label: 'Quarterly' },
    { value: 'half_yearly', label: 'Half Yearly' },
	{ value: 'custom_date', label: 'Custom Date' }
];
export const CURRENCY_INFO = {
	// "PARENT_CURRENCY":"IDR",
	// "CURRENT_CURRENCY":"IDR",
	// "FROM_CURRENCY":"IDR",
	"PARENT_CURRENCY":"PHP",
	"CURRENT_CURRENCY":"PHP",
	"FROM_CURRENCY":"PHP",
	"TO_CURRENCY":"USD",
	"CONVERSION_RATE":1
};
export const statusList = {'disbursement_done':'Disbursement Done','paid_to_dealer':"Paid to Dealer",'refund_received':"Refund Received",'disbursal_received':"Disbursal Received"};
export const statusArr = [
    { value: 'paid_to_dealer', label: 'Paid to Dealer',lead_source:['finance'] },
    { value: 'refund_received', label: 'Refund Received',lead_source:['finance'] },
	{ value: 'paid_to_dealer', label: 'Paid to Customer',lead_source:['refinance','bikerefinance'] },
    { value: 'refund_received', label: 'Bonus Received',lead_source:['refinance','bikerefinance'] },
    { value: 'disbursal_received', label: 'Disbursal Received',lead_source:['finance'] }
];

// manage menu Permission for Roles 
export const hasAccessPermission = (modulename,page='view') => {
	const userAccess = secureStorage.getItem("userAccess");
	let Permission = userAccess&& userAccess[modulename] && userAccess[modulename][page]=="1"?true:false;
	return Permission;
};
export const maxDATE = (from_date, d = 0)=>{
	const currentDate = new Date();
	var date = new Date(from_date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + d);

	// Check if the lastDay is greater than the current date
	return lastDay > currentDate ? currentDate : lastDay;
};

export const PRODUCT_TYPE = [
	{ name: 'UCF', value:"finance", label: 'Used Car Finance' },
	{ name: 'UCRF', value:"refinance", label: 'Used Car Refinance' },
	{ name: 'UBRF', value:"bikerefinance", label: 'Used Bike Refinance' }
];

export const FAM_ALLOWED_DOC_CATEGORY = [13, 14, 60, 61, 62, 17, 64, 18, 15, 51, 16, 52, 19, 53, 54, 58];
export const UNWIND_ARRAY = function(arr,by) {
	var results = [];
	arr.forEach(function(subArray) {
		let obj = {...subArray};
		delete obj[by];
		if(subArray[by].length){
			subArray[by].forEach(function(item) {
				item = {[by]:item,...obj};
				results.push(item);
			});
		}
		else{
			subArray[by]={};
			results.push(subArray);
		}
	});
	return results;
};
export const PRODUCT_TYPE_OBJECTS = {
	"finance" : 'UCF',
	"refinance" : 'UCRF',
	"bikerefinance" : 'UBRF'
};
export const BANK_LIST_OBJECTS = {
	"Unionbank":{acc_number : '000580021397',outbound_acc_number : '000580021397',label:'Unionbank'}
};
export const MAX_ADDITIONAL_REVENUE_SPLIT = 5;
export const DATE_FORMAT_MY = 'MM/yyyy';

export const AMOUNT_FORMAT_TYPE = [
	{ value: 'en', label: 'Indian Accounting Standard System' },
	{ value: 'id', label: 'Indonesian Accounting Standard System' }
]
export const RECONCILATION_STATUS = [
    { value: 'matched', label: 'Matched' },
    { value: 'notmatched', label: 'Not Matched' },
    { value: 'duplicate', label: 'Duplicate' },
    { value: 'manualmatched', label: 'Manual Matched' },
    { value: 'duplicatematched', label: 'Duplicate Matched' },
];
export const RECONCILATION_EXTENDED_DAYS = 2;
export const SPLIT_PAYMENT_TYPE_SOURCE_BASED = {
	finance:[
		{ value: "main_dealer", label: "Dealer Payment" },
		{ value: "split_dealer", label: "Split Payment" },
		{ value: "split_dealer2", label: "Split Payment2" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" },
		{ value: "onhold_dealer", label: "Onhold Dealer Payment" }
	],
	refinance:[
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" }
	],
	bikerefinance:[
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" }
	]
}

let curr_date = new Date();
let STATEMENT_ACTION_MAX_DATE = 31;
export const BANK_STATEMENT_OPERATIONAL_PERIOD = {
    minimum_date: new Date(curr_date.getFullYear(), curr_date.getMonth(), 1, 0, 0, 0 ),
    maximum_date: new Date(curr_date.getFullYear(), curr_date.getMonth(), STATEMENT_ACTION_MAX_DATE, 23, 59, 59),
};
export const TAX_VALUE = 1.09;/// 1.08
export const TAX_PPN = 11;/// 11%

export const OTHER_PAYMENT_TRANS_TYPE = [
	{ value: 1, label: 'Interest Amount' },
	{ value: 2, label: 'Other Payment' },
	{ value: 3, label: 'Reward' },
	{ value: 4, label: 'Sweep-out Transaction' },
	{ value: 5, label: 'Wrong Transfer' },
	{ value: 6, label: 'Excess Amount' },
	{ value: 7, label: 'Top Up' }

];

// Radis
export const IS_REDIS = false;
export const SHOW_CAPTCHA = false  // process.env.REACT_APP_SHOW_CAPTCHA;
console.log(process.env)

export const SFA_ROLE_NAME_OBJECTS = {
	"6" : 'SO',
	"5" : 'SM',
	"4" : 'TH',
	"3" : 'NHS'
};

export const REDIRECT_URLS = {
	"beta" : "http://sso-beta.carmudi.com.ph/google?redirect=",
	"preprod" : "https://pre-sso.carmudi.com.ph/google?redirect=",
	"local" : "http://sso-beta.carmudi.com.ph/google?redirect=",
	"prod" : " https://sso.carmudi.com.ph/google?redirect="
}
export const REDIRECT_URL = REDIRECT_URLS[process.env.REACT_APP_ENV];
export const SHOW_EMAIL_LOGIN_APP_TYPE = ['local','beta','preprod'];

export const getURL2Redirect = () => {
	let url2redirct = `/outbound-report/pending/finance`
	if (hasAccessPermission('finance-report') && hasAccessPermission('fam_outbond_payments')) {
		url2redirct = `/outbound-report/pending/finance`
	}
	else if (hasAccessPermission('finance-report') && hasAccessPermission('fam_inbond_payments')) {
		url2redirct = `/inbound-report/pending/finance`
	}
	return url2redirct;
}